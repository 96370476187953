.sociable {
  margin: 0px;
  padding: 0px;
  list-style: none;
  li {
    color: $brand-primary;
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }

    a {
      color: inherit;
    }
  }
}

#top-bar {
  .sociable {
    text-align: right;
    li {
      color: $white;
    }
  }
}

@media screen and (max-width:1199px) {
  #top-bar {
    .sociable {
      text-align: center;
    }
  }
}
