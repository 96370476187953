.list5 {
  ul {
    margin: 0 -30px;
    padding: 0;
    &.list5 {
      margin: 0 -30px;
      padding: 0;
    }
  }
  li {
    list-style: none;
    width: 33.33333%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    margin: 0 -3px 35px;
  }
  .content {
    span {
    display: block;
    &.country-name {
      margin-bottom: 10px;
      display: block;
      text-transform: uppercase;
      font-size: rem-calc(12);
      color: #333;
      font-family: $montserrat-sans-serif;
    }
  }
  h4 {
    font-size: rem-calc(18);
    line-height: ( 22 / 18 );
    font-weight: 400;
    margin-bottom: 15px;
    color: #000;
  }
  p {
    font-size: rem-calc(14);
    line-height: ( 22 / 14 );
    margin-bottom: 40px;
    color: #000;
  }
  }
  .figure {
    margin-bottom: 10px;
    display: block;
  }
  .image {
      width: 100%;
    }
}

