.box1 {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height:100%;

  &.testimonial {
    blockquote {
      margin: 0px;
    }
    p {
      &.cite {
        text-align: center;
        margin-bottom: 0px;
        cite {
          display: inline-block;
          max-width: 160px;
          font-style: normal;
          font-size: rem-calc(18);
          line-height: (22 / 18);
        }
      }
    }
    .details {
      background: none;
    }
  }

  .image {
    width: 100%;
    visibility: hidden;
  }
  .details {
    background: rgba($black, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: $white;
    .inner-wrap {
      display: table;
      width: 100%;
      height: 100%;
      .inner {
        display: table-cell;
        vertical-align: middle;
        padding: 0px 70px;
      }
    }

  }
}

#sidebar {
  .box1 {
    .details {
      font-family: $montserrat-sans-serif;
      text-align: center;
      h4 {
        font-size: rem-calc(20);
        line-height: (26 / 20);
        margin-bottom: 25px;
        letter-spacing: 1px;
      }

      p {
        line-height: (19 / 16);
        margin-bottom: 15px;
      }
      .button1 {
        padding: 5px 20px;
        font-weight: 400;
        border-radius: 0px;
      }
    }
  }
}

@media screen and (max-width:1199px) {
  .box1 {
    &.testimonial {
      p {
        margin-bottom: 15px;
        &.cite {
          cite {
            font-size: rem-calc(16);
            line-height: (22 / 16);
          }
        }
      }
    }
    .details {
      .inner-wrap {
        .inner {
          padding: 0px 30px;
        }
      }
    }
  }
}

@media (max-width:1199px) and (min-width:992px){
  .box1 {
    .details {
      .inner-wrap {
        .inner {
          padding: 0 45px;
        }
      }
    }
  }
}

@media (max-width:767px){
  #sidebar {
      .box1 {
      .details {
        h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
