#dashboard {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  .column {
    width: 33.33%;
    float: left;
  }
  .social-feed {
    padding: 40px 90px 30px 60px;
  }
  .sign-up-form {
    padding: 40px 75px 60px 60px;;
  }
}

@media screen and (max-width:991px) {
  #dashboard {
    .column {
      width: 50%;
      &.sign-up-form {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:767px) {
  #dashboard {
    .column {
      width: 100%;
      float: none;
    }
    .sign-up-form {
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width:599px) {
  #dashboard {
    .social-feed {
      padding: 40px 60px 30px 15px;
    }
    .sign-up-form {
      padding: 40px 60px 30px 15px;
    }
  }
}
