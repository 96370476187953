.widget {
  &.text-widget {
    margin-top: 32px;
  }
  &.list1 {
    border-left: 1px solid #f8783a;
    padding-left: 15px;
  }
  h3 {
    font-size: rem-calc(18);
    line-height: (22 / 18);
    margin-bottom: 35px;
  }
  h4 {
    font-size: rem-calc(14);
    line-height: (18 / 14);
    margin-bottom: 35px;
  }
  &.list {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    font-size: rem-calc(16);
    list-style: none;
    color: #f87a3e;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: $montserrat-sans-serif;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
p {
    small {
      font-size: rem-calc(14);
      line-height: (26 / 14);
    }
    &.link {
      margin-bottom: 20px;
      text-decoration: underline;
      color: $orange;
      a {
        color: inherit;
      }
      .icon {
        opacity: 0.8;
        margin-left: 10px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  &.normal {
    h3 {
      font-weight: 400;
      margin-bottom: 20px;

    }
  }
}

@media screen and (max-width:767px) {
  .widget {
    h4 {
      margin-bottom: 25px;
    }
  }
}
