.sport-theme {
  .school-trips-tours {
    li {
      width: 220px;
      padding: 35px 30px;
      p {
        color: #000;
      }
      h4 {
        color: #f26522;
      }
      .details {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
