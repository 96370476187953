body.two-col-layout {
  #content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.two-column-layout {
  .column1, .column2 {
    float: left;
    margin-bottom: 60px;
  }
  .column1 {
    width: 63%;
  }
  .column2 {
    width: 37%;
  }
}

@media screen and (max-width:991px) {
  .two-column-layout {
    .column1, .column2 {
      float: none;
      margin-bottom: 30px;
    }
    .column1 , .column2 {
      width: 100%;
    }
  }
}
