.section3 {
  background: #F7F7F7;
  padding: 30px 0 55px;
}

@media (min-width:1200px){
  .section3 {
    .container {
      max-width: 730px;
    }
  }
}
