.list2 {
  margin: 0px 0px 30px;
  padding: 0px;
  list-style: none;
  li {
    font-size: rem-calc(18);
    line-height: (28 / 18);
    color: $brand-primary;
    padding-left: 30px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 12px;
      background: $brand-primary;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &.strengths {
    li {
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
}


