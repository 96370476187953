#footer-menu {
  margin-bottom: 35px;
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  li {
    display: inline-block;
    color: $blue-dark;
    font-weight: 600;
    font-size: rem-calc(14);
    line-height: (17 / 14);
    font-weight: 600;
    margin-right: 35px;
    &:last-child {
      margin-right: 0px;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
@media screen and (max-width:991px) {
  #footer-menu {
    li {
      width: 50%;
      margin: 0px -2px;
    }
  }
}

@media screen and (max-width:599px) {
  #footer-menu {
    margin-bottom: 25px;
    li {
      display: block;
      width: 100%;
      margin: 0px 0px 5px;
    }
  }
}
