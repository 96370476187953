.list1 {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  li {
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(14);
    line-height: (18 / 14);
    color: #F8783A;
    margin-bottom: 10px;
    padding-left: 12px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 6px;
      background: #167445;
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
