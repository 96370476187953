.box5 {
  background: rgba(56,130,153,0.8);
  color: $white;
  position: relative;
  height: 300px;
  text-align: center;
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 65px;
  }
  .inner-wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }
  .inner {
    display: table-cell;
    vertical-align: middle;
  }
  blockquote {
    p {
      font-family: $montserrat-sans-serif;
      &.lead {
        font-size: rem-calc(24);
        line-height: (33 / 24);
        margin-bottom: 10px;
      }
      &.cite {
        font-size: rem-calc(14);
        line-height: (18 / 14);
        margin: 0;
      }
    }
  }
}

@media (max-width:767px){
  .box5 {
    .wrapper {
      padding: 0 20px;
    }
  }
}
