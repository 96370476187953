.display-2 {
  line-height: (48 / 48);
}

.display-5 {
    font-size: rem-calc(20);
    line-height: (32 / 20);
    margin-bottom: 25px;
}

.display-6 {
    font-size: rem-calc(18);
    line-height: (31 / 18);
    margin-bottom: 20px;
}

@media screen and (max-width:1199px) {
  .display-2 {
    font-size: rem-calc(36);
  }
}

@media screen and (max-width:599px) {
   .display-2 {
     font-size: rem-calc(26);
   }
}
