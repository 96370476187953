.call-to-action {
  background: $blue-dark;
  color: $white;
  padding: 30px 40px 20px 60px;
  .title {
    display: inline-block;
    background: $blue-light;
    font-size: rem-calc(13);
    line-height: (16 / 13);
    color: $black;
    padding: 1px 30px 1px 15px;
    margin-bottom: 30px;
    margin-left: -60px;
    font-weight: 400;
  }
  p {
    font-family: $montserrat-sans-serif;
    width: 97%;
    &.lead {
      font-size: rem-calc(22);
      line-height: (31 / 22);
      font-weight: 400;
    }
  }
  .button2 {
    margin-bottom: 17px;
  }
  footer {
    border-top: 1px solid #F3F3F3;
    padding-top: 18px;
    padding-right: 7px;
    p {
      font-size: rem-calc(12);
      line-height: (15 / 12);
      margin-bottom: 15px;
    }
    .protection-schemes {
      margin: 0px;
      padding: 0px;
      list-style: none;
      text-align: right;
      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

@media screen and (max-width:1199px) {
  .call-to-action {
    padding-left: 40px;
    .title {
      margin-left: -40px;
    }
    p {
      &.lead {
        font-size: rem-calc(20);
      }
    }
  }
}

@media screen and (max-width:480px) {
  .call-to-action {
    p {
      &.lead {
        font-size: rem-calc(16);
      }
    }
  }
}
