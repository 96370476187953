.sign-up-form {
  background: $green;
  color: $light-black;

  h4 {
    font-size: rem-calc(20);
    line-height: (24 / 20);
    margin-bottom: 25px;
    .mail-icon {
      display: inline-block;
      float: right;
      margin-left: 20px;
      background: url('../images/mail-icon.svg') no-repeat;
      width: 40px;
      height: 27px;
    }
  }

  p {
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(14);
    line-height: (19 / 14);
    margin-bottom: 15px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  li {
    margin-bottom: 13px;
  }

  input[type="text"], input[type="email"] {
    display: block;
    width: 100%;
    background: $white;
    color: #9B9B9B;
    font-size: rem-calc(12);
    line-height: (14 / 12);
    border: 0px;
    padding: 5px 10px;;
      &:-moz-placeholder {
          opacity: 1;
          color: $gray-lighter;
      }
      &::-moz-placeholder {
          opacity: 1;
          color: $gray-lighter;
      }
      &::-webkit-input-placeholder {
          opacity: 1;
          color: $gray-lighter;
      }
      &:-ms-input-placeholder {
          opacity: 1;
          color: $gray-lighter;
      }
  }

  .sign-up-btn {
    background: $white;
    color: $light-black;
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(14);
    line-height: (18 / 14);
    padding: 5px 10px;
    text-transform: uppercase;
    min-width: 133px;
    border: 0px;
    display: inline-block;
    text-align: center;
  }
}
