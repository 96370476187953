.team-details-section , #carousel {
  position: relative;
  margin-bottom: 0px;
  max-height: 448px;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    float: left;
  }
  &.offer-details , &.performing-arts , &.ski , &.sports , &.hotel {
    .previous-button, .next-button {
      font-size: rem-calc(48);
      color: rgba(255, 255, 255, 0.43);
      text-decoration: none;
      @include vertical-center;
      z-index: 9;
    }
    .previous-button {
      left: 20px;
    }
    .next-button {
      right: 20px;
    }
  }
    &.hotel {
     margin: 0;
     .previous-button, .next-button {
       color: $white;
   }
   .inner {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 0 45px;
          p {
            font-family: $montserrat-sans-serif;
        }
   }
  .box1 {
    .details {
      background: rgba(0,0,0,0);
    }
  }
 }
}
