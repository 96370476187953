.team-member {
  .figure {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    margin-bottom: 10px;
    .image {
      width: 100%;
    }
  }
  h5 {
     font-size: rem-calc(20);
     color: $light-black;
     font-weight: 600;
     line-height: (32 / 20);
     font-family: $font-family-sans-serif;
     margin: 0;
  }
  p {
    font-size: rem-calc(16);
    color: $light-black;
    line-height: (22 / 16);
    margin-bottom: 20px;
    &.lead {
      font-size: rem-calc(18);
      line-height: (22 / 18);
    }
  }
  .circle {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: block;
    background: #388299;
    margin: 0 auto 25px;
    .icon {
      font-size: rem-calc(40);
      text-align: center;
      vertical-align: middle;
      line-height: 110px;
      color: $white;
    }
  }
}
