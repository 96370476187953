#header {
  .search-icon {
    font-size: rem-calc(18);
  }
}
.search-icon {
  float: right;
  background: rgba($blue-lighter, 0);
  color: $secondary-color;
  width: 52px;
  height: 52px;
  text-align: center;
  padding-top: 8px;
  margin-top: 13px;
  margin-left: 25px;
  margin-right: -23px;
  cursor: pointer;
  @include cubic-transition(background);
}
#search-form {
  background: $blue-lighter;
  width: 100%;
  padding: 13px 0px 9px;
  position: absolute;
  left: 0;
  top: 77px;
  z-index: 99;
  display: none;
  .container {
    max-width: 750px;
  }
  form {
    position: relative;
  }
  input[type="text"], .gsc-input {
    background: url("../images/google-search.svg") no-repeat 17px center $white;
    display: block;
    width: 100%;
    border: 0px;
    padding: 14px 10px 12px !important;
    text-align: center;
    font-size: rem-calc(16);
    line-height: (22 / 16);
    font-weight: 600;
    color: $light-black;
    &:-moz-placeholder {
        opacity: 1;
        color: $light-black;
    }
    &::-moz-placeholder {
        opacity: 1;
        color: $light-black;
    }
    &::-webkit-input-placeholder {
        opacity: 1;
        color: $light-black;
    }
    &:-ms-input-placeholder {
        opacity: 1;
        color: $light-black;
    }
  }
  .search-btn, .cse .gsc-search-button input.gsc-search-button-v2, input.gsc-search-button-v2 {
    background: url('../images/search-btn.svg') #fff no-repeat;
    border: 0px;
    padding: 0px;
    margin: 0px;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 33px;
    top: 15px;
  }
  .cse .gsc-control-cse, .gsc-control-cse, .cse form.gsc-search-box, form.gsc-search-box {
    padding: 0px;
  }
  form.gsc-search-box {
    margin-bottom: 0px;
  }
  table, .gsc-input-box {
    border: 0px;
    box-shadow: none;
  }
}

#header {
  &.open-search {
    .search-icon {
      background: rgba($blue-lighter, 1);
    }
    #search-form {
      display: block;
    }
  }
}

@media screen and (max-width:991px) {
  .search-icon {
    margin-right: 0px;
  }
}
