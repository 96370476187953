.respond-form {
   background: $white;
   padding: 25px 30px;
   p {
     small {
       text-transform: uppercase;
       color: #3C3D41;
       font-weight: 700;
       letter-spacing: 2px;
       font-size: rem-calc(10);
       font-family: $montserrat-sans-serif;
     }
     &.form-title {
       padding-bottom: 7px;
       border-bottom: 1px solid #E7E7E7;
       margin: 0 -30px 15px;
     }
     &.form-submit {
       float: right;
       margin: 0;
     }
   }
   .client-image {
     float: left;
     vertical-align: top;
     width: 50px;
     height: 50px;
     border-radius: 50%;
   }
   textarea {
     float: right;
     width: 88%;
     border-radius: 4px;
     background: #EEEEEE;
     resize: none;
     min-height: 100px;
     border: 0;
     padding: 15px;
     font-size: rem-calc(14);
   }
   input[type="text"] , input[type="email"] {
     border-radius: 4px;
     background: #EEEEEE;
     border: 0;
     padding: 10px;
     font-size: rem-calc(14);
     width: 100%;
   }
}


@media (max-width:543px){
  .respond-form {
    padding: 25px 15px;
    .client-image {
      float: none;
      margin-bottom: 15px;
    }
    textarea {
      width: 75%;
    }
    p {
      &.form-title {
        margin: 0 -15px 15px;
      }
    }
  }
}
