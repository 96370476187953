.quote-button {
  display: block;
  background: $brand-primary;
  color: $white;
  font-family: $montserrat-sans-serif;
  font-size: rem-calc(14);
  line-height: (17 / 14);
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 15px 7px;
  border-radius: 3px;
  text-decoration: none;
  width: 167px;
  margin: 0 auto;
  &:hover, &:focus {
    color: $white;
    text-decoration: none;
  }
}

@media (max-width:991px){
  #topbar {
    p {
      white-space: normal;
    }
  }
}
