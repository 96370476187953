#slideshow {
  position: relative;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  .slides {
    position: relative;
    width: 100% !important;
    height: 100%;
    min-height: 372px;
    .slide {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block !important;
      opacity: 0;
      top: 0;
      left: 0;
      min-height: 377px;
    }
    .slide:first-child {
      opacity: 1;
    }
  }
  .controls {
     position: absolute;
     bottom: 20px;
     width: 100%;
     text-align: center;
     z-index: 100;
     a {
       display: inline-block;
       width: 6px;
       height: 6px;
       transform: scale(1);
       border-radius: 50%;
       margin: 0 2px;
       background: rgba(255, 255, 255, 0.7);
       vertical-align: middle;
       text-decoration: none;
       overflow: hidden;
       @include cubic-transition;
       &:hover , &.activeSlide {
         transform: scale(1.5);
         border-radius: 50%;
         background: $white;
         @include cubic-transition;
       }
     }
  }
  .arrows {
     z-index: 10;
     width: 100%;
     @include vertical-center;
     .icon {
        color: $white;
        text-decoration: none;
        font-size: rem-calc(64);
        @include vertical-center;
        &.fa-angle-left {
          left: 25px;
        }
        &.fa-angle-right {
          right: 25px;
        }
     }
  }
}


@media (max-width:1200px){
  #slideshow {
    height: 368px;
    overflow: hidden;
  }
}


@media (max-width:991px){
  #slideshow {
    height: auto;
    &.featured-section {
       .image {
         min-height: 372px;
       }
    }
  }
}
