#footer {
  .exit-footer {
    .inner-wrap {
      padding-top: 35px;
      border-top: 1px solid #E4F1FD;
    }
  }
  p {
    font-family: $roboto-sans-serif;
    font-size: rem-calc(12);
    line-height: (25 / 12);
    color: $brand-primary;
    margin-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  #footer {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
    .exit-footer {
      .inner-wrap {
        padding-left: 25px;
        padding-right: 25px;
      }
      .container {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1170px;
      }
    }
  }
}
@media (max-width: 767px) {
  #footer {
    .exit-footer {
      .inner-wrap {
        padding-top: 25px;
      }
    }
  }
}
