.featured-section {
  position: relative;
  .image {
    background-repeat: no-repeat;
    background-size: cover;
    img {
      visibility: hidden;
      width: 100%;
    }
  }
  .testimonial {
    background: rgba($black, 0.3);
    color: $white;
    font-family: $montserrat-sans-serif;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    .inner-wrap {
      width: 100%;
      height: 100%;
      display: table;
      .inner {
        display: table-cell;
        vertical-align: middle;
      }
    }

    blockquote {
      max-width: 445px;
      margin: 0px auto;
    }

    p {
      &.lead {
        margin-bottom: 70px;
        line-height: (36 / 24);
      }
      &.cite {
        margin-bottom: 0px;
        cite {
          font-size: rem-calc(18);
          line-height: (22 / 18);
          font-style: normal;
        }
      }
    }

  }
}

@media screen and (min-width:992px) {
  .featured-section {
    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width:767px) {
  .featured-section {
    .image {
      min-height: 236px;
    }
  }
}
