#comments {
  position: relative;
  h3 {
    margin-bottom: 35px;
    span {
      &.number {
        display: inline-block;
        width: 21px;
        height: 15px;
        background: #60AFBB;
        border-radius: 13px;
        color: $white;
        font-weight: 700;
        vertical-align: middle;
        line-height: 15px;
        font-size: rem-calc(10);
      }
    }
  }
  .editor {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid #B8C6C7;
    display: block;
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 0;
    .icon {
      color: #838D8F;
      line-height: 42px;
      text-align: center;
      font-size: rem-calc(16);
      display: block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  .comment-list {
    li {
      article {
        border-top: 1px solid #E7E7E7;
        padding: 25px 0;
      }
    }
    .children {
      li {
        &:nth-child(1){
            .inner-wrapper {
            padding-left: 70px;
          }
        }
        &:nth-child(2){
            .inner-wrapper {
            padding-left: 140px;
          }
        }
      }
    }
  }
  .avtar-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
    .name {
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: rem-calc(10);
      color: #3C3D41;
      line-height: (18 / 10);
      font-family: $montserrat-sans-serif;
      display: inline-block;
      margin-bottom: 10px;
  }
  .metadata {
    margin-left: 7px;
    time {
      color: #4A4A4A;
      font-size: rem-calc(12);
      font-style: italic;
    }
    .icon {
      color: #C4D0D1;
      font-size: rem-calc(14);
      margin-right: 10px;
    }
  }
  .comment-meta {
    float: left;
  }
  .comment-content {
    padding-left: 70px;
  }
  .reply {
    background-image: url("../images/reply-icon.svg");
    display: block;
    margin-left: 70px;
    padding-left: 25px;
    background-position: left center;
    background-repeat: no-repeat;
    color: #838D8F;
    font-size: rem-calc(10);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $montserrat-sans-serif;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 10px;
  }
}

@media (max-width:543px){
  #comments {
    .comment-list {
      .children {
        li {
          &:nth-child(1) {
            .inner-wrapper {
              padding-left: 15px;
            }
          }
          &:nth-child(2) {
            .inner-wrapper {
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
