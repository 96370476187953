.school-trips-tours {
  .container {
    position: relative;
  }
  .previous-button, .next-button {
    color: $blue-dark;
    font-size: rem-calc(48);
    text-decoration: none;
    @include vertical-center;
    z-index: 9;
  }
  .previous-button {
    left: 0px;
  }
  .next-button {
    right: 0px;
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  li {
    width: 275px;
    float: left;
    padding: 35px 45px;
    border-left: 1px solid $blue-light;
    text-align: center;
    &:first-child {
      border-left: 0px;
    }
    .details {
      padding: 15px 15px 0px;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    h4 {
      margin-bottom: 10px;
    }
    p {
      font-family: $montserrat-sans-serif;
      font-size: rem-calc(13);
      line-height: (20 / 13);
      margin: 0px;
      color: #111;
    }
  }
}

.sport-theme {
  .school-trips-tours {
    h4 {
      color: $sport-theme;
    }
  }
}
.ski-theme {
  .school-trips-tours {
    h4 {
      color: $ski-theme;
    }
  }
}
.adventure-theme {
  .school-trips-tours {
    h4 {
      color: #4BCA8B;
    }
  }
}
.performing-arts-theme {
  .school-trips-tours {
    h4 {
      color: $performing-arts-theme;
    }
  }
}
.teacher-guide-theme {
  .school-trips-tours {
    h4 {
      color: $teacher-guide-theme;
    }
  }
}

@media screen and (max-width:599px) {
  .school-trips-tours {
    .previous-button {
      left: 5px;
    }
    .next-button {
      right: 5px;
    }
    li {
      padding: 25px 20px;
      .details {
        padding: 15px 5px 0px;
      }
    }
  }
}
