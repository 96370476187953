form {
  input, select, textarea {
    &:focus {
      outline: none;
    }
  }
}

.form {
  h3 {
    line-height: (35 / 22);
    letter-spacing: -0.4px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 50px;
  }
  ul {
    margin: 0 -7px;
    padding: 0;
  }
  li {
    list-style: none;
    width: 50%;
    padding: 0 7px;
    margin-bottom: 15px;
    float: left;
    &.full-width {
      width: 100%;
    }
    .selectbox {
      position: relative;
      display: block;
          &:after {
          display: inline-block;
          content: '';
          background: url(../images/dropdown-after.png);
          width: 30px;
          height: 51px;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 4px;
          pointer-events: none;
        }
      }
      &:last-child {
        margin: 0;
      }
  }
  input[type="text"] , input[type="email"] , textarea , input[type="search"] {
    width: 100%;
    border: 0;
    background: #dde9eb;
    color: #388299;
    font-size: rem-calc(14);
    border-radius: 4px;
    padding: 15px;
    font-family: $font-family-sans-serif;
  }
  input, textarea {
      &:-moz-placeholder {
          opacity: 1;
          color: #388299;
      }
      &::-moz-placeholder {
          opacity: 1;
          color: #388299;
      }
      &::-webkit-input-placeholder {
          opacity: 1;
          color: #388299;
      }
      &:-ms-input-placeholder {
          opacity: 1;
          color: #388299;
      }

  }
  select {
    overflow: hidden;
    width: 100%;
    border: 0;
    background: #dde9eb;
    color: #388299;
    font-size: rem-calc(14);
    border-radius: 4px;
    padding: 15px;
    height: 51px;
    font-family: $font-family-sans-serif;
  }
  textarea {
    min-height: 52px;
  }
  .button6 {
    width: 100%;
    padding: 15px;
    border: 0;
  }
    label {
     letter-spacing: -0.3px;
     color: #4a4a4a;
     font-size: rem-calc(18);
     line-height: 1.94;
     margin-bottom: 0;
     font-family: $montserrat-sans-serif;
  }
}

@media (max-width:1200px){
  .form {
    label {
      font-size: rem-calc(16);
    }
  }
}

@media (max-width:767px){
  .form {
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
    }
  }
}

@media (max-width:543px){
  .form {
    li {
      width: 100%;
    }
  }
}

