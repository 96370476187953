.sportsfest-template3 {
    a {
        text-decoration: none;
    }
    .entry p { margin-bottom: 12px; }
    .two-column-layout {
        .column1 {
            width: 50%;
            margin-bottom: 0px;
            .lead {
                margin-bottom: 40px;
            }
            .entry p {
                margin-bottom: 40px;
            }
            h3 {
                font-size: rem-calc(18);
            }
            .fa-file-text-o {
                padding-right: 15px;
                font-size: rem-calc(20);
            }
            a {
                font-size: rem-calc(14);
                font-family: $montserrat-sans-serif;
            }
        }
        .column2 {
            width: 50%;
            margin-bottom: 0px;
            .box2.widget {
                .display-6 {
                    margin-bottom: 35px;
                }
            }
        }
    }
    .row {
        .column:nth-child(even) {
            padding-left: 0;
        }
        .column:nth-child(odd) {
            padding-right: 0;
        }
        .entry {
            padding-left: 45px;
            padding-top: 30px;
            h3 {
                font-size: rem-calc(24);
                font-weight: bold;
                font-style: normal;
                color: #388299;
            }
        }
    }
    .location {
        h4 {
            font-size: rem-calc(24);
            line-height: 1.5;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .marker {
            font-size: rem-calc(18);
            padding-left: 25px;
            position: relative;
            line-height: 1.5;
            &:after {
                content: "\f041";
                font-family: FontAwesome;
                position: absolute;
                font-size: 24px;
                color: #ffffff;
                top: 50%;
                left: 0%;
                z-index: 1;
                transform: translateY(-50%);
            }
        }
    }
    .accommodation {
        .column:nth-child(odd) {
            padding-left: 0;
        }
        .column:nth-child(even) {
            padding-right: 0;
        }
        .entry {
            a {
                text-decoration: underline;
            }
        }
    }
    .video {
        margin: 0px -30px;
        a {
            font-size: rem-calc(14);
            .fa {
                padding-right: 15px;
            }
        }
        h3 {
            font-size: rem-calc(24);
        }
        p {
            padding-bottom: 35px;
            border-bottom: 1px solid #9b9b9b;
            font-size: rem-calc(18);
        }
    }
    .offers {
      margin: 0px -15px;
        h3 {
            font-size: rem-calc(24);
            margin-bottom: 25px;
        }
        p {
            margin-bottom: 25px;
        }
        .details {
            background: none;
        }
        .button1 {
            font-weight: normal
        }
        ;
        .heading {
            display: inline-block;
            &:after {
                content: '';
                display: block;
                background: #ffffff;
                width: 97px;
                height: 2px;
                position: relative;
            }
        }
    }
    .enquiry-form{
      margin: 0px -15px;
    }
}
@media screen and (max-width: 991px) {
    .sportsfest-template3 {
        .two-column-layout .column1, .two-column-layout .column2 {
            width: 100%;
        }
        .row {
            .column:nth-child(even) {
                padding-left: 15px;
            }
            .column:nth-child(odd) {
                padding-right: 15px;
            }
            .entry {
                padding: 20px 0;
                h3 {
                    font-size: rem-calc(24);
                    font-weight: bold;
                    font-style: normal;
                    color: #388299;
                }
            }
        }
            .accommodation {
        .column:nth-child(odd) {
            padding-left: 15px;
        }
        .column:nth-child(even) {
            padding-right: 15px;
        }

    }
        .offers {
            min-height: 450px;
        }
            .video {
        margin: 0px -15px;
    }
    }
}
@media screen and (max-width: 767px) {
    .sportsfest-template3 {
        .video {
            margin: 0px -15px;
        }
        .accommodation {
            .column:nth-child(odd) {
                padding-left: 15px;
            }
            .column:nth-child(even) {
                padding-right: 15px;
            }
        }
    }
}
@media (max-width: 599px){
      .sportsfest-template3 {
.enquiry-form , .offers{
          margin: 0px 0px;
        }
}
}
