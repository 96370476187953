.map{
  position: relative;
  figure{
    margin-bottom: 0;
  }
  .mapimg{
    width:100%;
  }
  .marker{
    @include absolute-center;
  }
}
