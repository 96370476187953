.box4 {
      width: 50%;
      .image1 {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }
      .content {
        float: left;
        width: 50%;
        padding: 70px 20px 15px 20px;
        border-radius: 0 0 4px 4px;
        h3 {
          letter-spacing: -0.4px;
          color: #3C3D41;
          margin-bottom: 10px;
        }
        p {
          color: #4A4A4A;
          font-size: rem-calc(14);
          line-height: (18 / 14);
          margin-bottom: 20px;
        }
      }
      a {
        background: $white;
        display: block;
        border-radius: 4px;
        position: relative;
        &:hover {
          text-decoration: none;
        }
      }
      .tag {
        z-index: 10;
        text-align: center;
        border-radius: 3px;
        background: $purple;
        padding: 5px;
        font-weight: 700;
        font-size: rem-calc(10);
        text-transform: uppercase;
        font-family: $montserrat-sans-serif;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      &.performing-arts {
        @include cubic-transition;
        .overlay {
          background: $purple;
        }
        .tag {
          background: $purple;
        }
      }
      &.sport {
        .tag {
          background: $orange;
        }
      }
      &.ski {
        .tag {
          background: $green-dark;
        }
      }
      &.adventure {
        .tag {
          background: $green-light;
        }
      }
      &.guide {
        .tag {
          background: #388299;
        }
      }
    }

@media (max-width:991px){
  .box4 {
    .image1 {
      width: 35%;
    }
    .content {
      width: 65%;
    }
  }
}

@media (max-width:767px){
  .box4 {
    .content {
      h3 {
        font-size: rem-calc(18);
      }
    }
  }
}

@media (max-width:543px){
  .box4 {
    .content {
      width: 100%;
      padding: 15px;
    }
    .image1 {
      width: 100%;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}
