.adventure-theme {
   #main-navigation {
     .sub-menu {
       li {
         &.active {
           color: #000;
         }
       }
     }
   }
}
