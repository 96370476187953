/*
*banner
*/
#banner {
  position: relative;
  background: url(../images/banner-image1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  background-position: center center;
  color: $white;
  .banner-image {
    visibility: hidden;
    width: 100%;
  }
  .overlay {
    background: rgba(60,61,65,0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    text-align: center;
  }
  .wrapper {
    padding: 105px 0 55px;
    margin: 0 auto;
  }
  h1 {
    font-size: rem-calc(52);
    line-height: 1.15;
    letter-spacing: -0.2px;
    margin-bottom: 30px;
    line-height: (60 / 52);
  }
  .inner {
    max-width: 580px;
    margin: 0 auto;
    p {
        color: $blue-lighter;
        font-size: rem-calc(24);
        margin-bottom: 45px;
        line-height: (33 / 24);
        small {
          font-size: rem-calc(14);
          line-height: (19 / 14);
          color: $white;
          font-style: italic;
        }
        a {
          color: inherit;
          text-decoration:  none;
        }
        &.comments {
          p {
            a {
              font-size: rem-calc(12);
            }
          }
        }
    }
    .icon {
      margin-right: 15px;
      font-size: rem-calc(15);
      vertical-align: baseline;
    }
  }
  .button1 {
    min-width: 165px;
  }
  .tag {
    display: inline-block;
    border: solid 1px #ededed;
    border-radius: 4px;
    min-width: 60px;
    padding: 0 5px;
    color: #fefefe;
    font-size: rem-calc(9);
    font-weight: 700;
    font-family: $montserrat-sans-serif;
    text-decoration: none;
    letter-spacing: 2px;
    margin-bottom: 60px;
    line-height: 1.8;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
}

@media (min-width:1200px){
  #banner {
    .wrapper {
      max-width: 840px;
    }
  }
}
@media (max-width:767px){
  #banner {
    height: 350px;
    h1 {
      font-size: rem-calc(30);
      margin-bottom: 15px;
    }
    .inner {
      p {
      font-size: rem-calc(16);
      margin-bottom: 15px;
     }
    }
  }
}

@media (max-width:543px){
   #banner {
     height: 420px;
     .wrapper {
       padding: 70px 0;
     }
   }
}
