.blog-post {
  #banner {
    background: url(../images/banner-image2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .inner {
      max-width: 260px;
    }
  }
  .main-image {
      position: relative;
      margin: 0 -50px 80px;
      .image {
        width: 100%;
        display: block;
      }
      .description {
        background: #fdfdfd;
        padding: 30px;
        width: 200px;
        border-radius: 4px;
        position: absolute;
        top: 35px;
        left: 30px;
        h6 {
          color: #6c6c70;
          font-weight: 600;
          font-size: rem-calc(16);
          line-height: (22 / 16);
          font-style: italic;
          font-family: $font-family-sans-serif;
          margin-bottom: 30px;
        }
        p {
          font-size: rem-calc(12);
          color: #838d8f;
          margin: 0;
          line-height: (17 / 12);
        }
        .close-button {
          color: #838d8f;
          font-weight: 400;
          position: absolute;
          right: 15px;
          text-decoration: none;
          top: 15px;
        }
      }
    }
}

@media (max-width:991px){
  .blog-post {
    .main-image {
      margin: 0 -20px 40px;
    }
  }
}

@media (max-width:767px){
  .blog-post {
    .main-image {
      margin: 0 0 30px;
    }
  }
}
@media (max-width:543px){
  .blog-post {
    .main-image {
      margin: 0 0 20px;
      .description {
        padding: 15px;
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
      }
    }
  }
}
