.home {
  .entry {
    .heading {
      max-width: 620px;
    }
    .post-content {
      ul {
    margin: 0px 0px 45px;
    li {
      font-size: rem-calc(20);
      line-height: (31 / 20);
      color: $green-dark;
      &:before {
        background: $green-dark;
      }
    }
  }
    }
  }
}

@media screen and (max-width:767px) {
  body.home {
  .entry {
    .post-content {
      ul {
    li {
      font-size: rem-calc(16);
      line-height: (31 / 16);
    }
  }
    }
  }
}
}
