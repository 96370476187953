.contact-info {
  .form {
    textarea {
      max-height: 51px;
      min-height: 51px;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 45px;
    }
  }
}

@media (max-width:767px){
  .contact-info {
    .form {
      h3 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 25px;
      }
    }
  }
}
