.box3 {
    @include cubic-transition;
  .content {
       padding: 15px 13px;
       border-radius: 0 0 4px 4px;
    }
    .image1 {
      width: 100%;
      height: 170px;
    }
    a {
      background: $white;
      color: inherit;
      border-radius: 4px;
      display: block;
      position: relative;
      &:hover {
        text-decoration: none;
      }
    }
    h6 {
        font-size: rem-calc(15);
        line-height: 1.2;
        letter-spacing: -0.2px;
        color: #3b3d40;
    }
    p {
      font-size: rem-calc(14);
      line-height: 1.29;
      color: $light-black;
    }
  .tag {
    z-index: 10;
    text-align: center;
    border-radius: 3px;
    background: $purple;
    padding: 5px;
    font-weight: 700;
    font-size: rem-calc(10);
    text-transform: uppercase;
    font-family: $montserrat-sans-serif;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .overlay {
    z-index: 100;
    text-align: center;
    padding: 20px;
    background: $green-dark;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    @include cubic-transition;
    h1, h2 ,h3 ,h4 , h5 , h6 , p {
      color: $white;
    }
    span {
      font-weight: 700;
      font-size: rem-calc(10);
      font-family: $montserrat-sans-serif;
      color: $white;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    h4 {
      font-size: rem-calc(22);
    }
    p {
      font-size: rem-calc(14);
      line-height: 1.9;
    }
  }
  &.performing-arts {
    .overlay {
      background: $purple;
    }
    .tag {
      background: $purple;
    }
  }
  &.sport {
    .overlay {
      background: $orange;
    }
    .tag {
      background: $orange;
    }
  }
  &.ski {
    .overlay {
      background: $green-dark;
    }
    .tag {
      background: $green-dark;
    }
  }
  &.adventure {
    .overlay {
      background: $green-light;
    }
    .tag {
      background: $green-light;
    }
  }
  &.guide {
    .overlay {
      background: #388299;
    }
    .tag {
      background: #388299;
    }
  }
  .read-more-button {
    width: 130px;
    margin: 0 auto;
  }
}

@media (max-width:1200px){
  .box3 {
    .overlay {
      padding: 20px;
    }
  }
}

@media (max-width:767px){
  .box3 {
    .content {
      padding: 15px;
    }
  }
}
