.list3 {
    margin: 0 -10px;
    padding: 0;
  ul {
    margin: 0 -10px;
    padding: 0;
  }
  li {
    list-style: none;
    width: 25%;
    display: inline-block;
    margin: 0 -2px 20px;
    vertical-align: top;
    padding: 0 10px;
    position: relative;
    &.featured {
      width: 50%;
    }
    a {
      min-height: 320px;
      max-height: 320px;
      overflow: hidden;
      text-decoration: none;
        &:hover , &:focus {
          text-decoration: none;
          .overlay {
            opacity: 1;
          }
        }
    }
  }
}

@media (max-width:1200px){
  .list3 {
    li {
      a {
        max-height: 350px;
        min-height: 350px;
      }
    }
  }
}

@media (max-width:991px){
  .list3 {
    li {
      width: 50%;
    }
  }
}

@media (max-width:543px){
  .list3 {
    li {
      width: 100%;
      a {
        max-height: inherit;
        min-height: inherit;
        max-width: 230px;
        margin: 0 auto;
      }
      &.featured {
        width: 100%;
      }
    }
  }
}
