.hotel-page {
  .box2 {
    a {
      color: inherit;
      text-decoration: none;
    }
    &.form {
      padding: 15px 0;
      ul {
        margin: 0 -8px;
      }
      li {
        display: inline-block;
        float: none;
        vertical-align: top;
        width: auto;
        padding: 0 15px;
        margin-bottom: 0;
      }
      label {
        line-height: 50px;
        font-size: rem-calc(16);
        margin-right: 30px;
              }
      .selectbox , input[type="search"] , input[type="text"] , input[type="email"] {
        float: right;
        width: 300px;
        border-radius: 8px;
        background: $white;
      }
      select {
       background: $white;
      }
      .selectbox {
        &:after {
          background: url(../images/dropdown2-after.png);
        }
      }
    }
    &.form {
      .button1 {
        color: #ffffff;
        display: block;
        float: right;
        max-width: 115px;
        min-width: 115px;
        padding: 16px;
        border: 0;
      }
    }
  }
  .list5 {
    padding: 55px 0;
    margin: 0 -30px;
    .button3 {
      width: 125px;
    }
  }
  .heading {
    margin-bottom: 50px;
  }
  .form {
    li {
      width: 33.3333%;
    }
  }
}

@media (max-width:1200px){
  .hotel-page {
    .box2 {
      &.form {
        ul {
          margin: 0 -15px;
        }
        li {
          padding: 0 15px;
        }
        label {
          font-size: rem-calc(13);
        }
        .selectbox , input[type="search"] , input[type="text"] , input[type="email"] {
             width: 225px;
        }
      }
    }
  }
}

@media (max-width:991px){
  .hotel-page {
    .box2 {
      &.form {
        li {
          width: 100%;
          margin-bottom: 10px;
        }
        .selectbox , input[type="search"] , input[type="text"] , input[type="email"] {
             width: 80%;
        }
      }
    }
    .list5 {
    li {
      width: 50%;
    }
   }
  }
}

@media (max-width:767px){
  .hotel-page {
    .box2 {
      &.form {
        label {
          margin-right: 15px;
        }
        .selectbox , input[type="search"] , input[type="text"] , input[type="email"] {
             width: 75%;
        }
      }
    }
  }
}

@media (max-width:543px){
  .hotel-page {
    .box2 {
      &.form {
         .selectbox , input[type="search"] , input[type="text"] , input[type="email"] {
           width: 100%;
           float: none;
         }
         label {
           float: none;
           width: 100%;
           line-height: 1;
         }
      }
    }
    .list5 {
      li {
        width: 100%;
      }
    }
  }
}
