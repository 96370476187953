.heading {
  padding-bottom: 0px;
  position: relative;
  &:after {
    content: '';
    display: block;
    background: $secondary-color;
    width: 96px;
    height: 5px;
    position: relative;
    bottom: -15px;
  }
  &.text-xs-center {
    &:after {
      margin: 0 auto;
    }
  }
  &.text-xs-right {
    &:after {
      left: auto;
      right: 0;
    }
  }
    &.box1 {
    .heading {
      &:after {
        background-color: $green-dark;
      }
    }
  }
}

.text-xs-right {
  .heading {
    &:after {
      left: auto;
      right: 0;
    }
  }
}

h2 {
  &.heading {
    margin-bottom: 30px;
  }
}

h3 {
  &.heading {
    font-size: rem-calc(24);
    line-height: (36 / 24);
    margin-bottom: 50px;
  }
}

.sport-theme {
  .heading {
    &:after {
      background-color: $sport-theme;
    }
  }
}
.ski-theme {
  .heading {
    &:after {
      background-color: $ski-theme;
    }
  }
}
.adventure-theme {
  .heading {
    &:after {
      background-color: $adventure-theme;
    }
  }
}
.performing-arts-theme {
  .heading {
    &:after {
      background-color: $performing-arts-theme;
    }
  }
}
.teacher-guide-theme {
  .heading {
    &:after {
      background-color: $teacher-guide-theme;
    }
  }
}
