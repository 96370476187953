.team-details {
  .content {
    padding: 40px 15px 0;
  }
  h3 {
    color: #3B3D40;
    margin-bottom: 5px;
  }
  p {
    font-size: rem-calc(16);
    &.metadata {
      margin-bottom: 30px;
    }
  }
  .controls {
    .icon {
        color: #024256;
        text-decoration: none;
        font-size: rem-calc(64);
        position: absolute;
        @include vertical-center;
        &:focus , &:hover {
          text-decoration: none;
        }
        &.fa-angle-left {
          left: 0;
        }
        &.fa-angle-right {
          right: 0;
        }
    }
  }
  .figure {
    .image {
      width: 100%;
    }
  }
  .wrapper {
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
    padding: 0 60px;
  }
}

@media (max-width:767px){
   .team-details {
     padding: 0 10px;
     .wrapper {
       padding: 0 40px;
     }
     .controls {
       .icon {
         font-size: rem-calc(32);
       }
     }
     .content {
       padding: 0;
     }
     p {
       &.metadata {
         margin-bottom: 10px;
       }
     }
   }
}

@media (max-width:543px){
  .team-details {
    .wrapper {
      padding: 0 20px;
    }
  }
}
