.enquiry-form {
    text-align: center;
        background-color: #dde9eb;
    form {
        input, select, textarea {
            &:focus {
                outline: none;
            }
        }
    }
    &.form {
        .container {
          max-width: 486px;
        display: block;
        margin: 0 auto;
        }
        h3 {
            font-weight: bold;
            line-height: 1.33;
            text-align: center;
            color: #4a4a4a;
            margin-bottom: 20px;
            padding-bottom: 25px;
            display: inline-block;
            &:after {
              content: '';
              display: block;
              background: #979797;
              width: 138px;
              height: 1px;
              position: relative;
              top: 25px;
              left: 50%;
              transform: translateX(-50%);
            }

        }
        p {
            font-size: rem-calc(18);
            font-weight: normal;
            text-align: center;
            color: #4a4a4a;
        }
        ul {
            margin: 0 -7px;
            padding: 0;
        }
        li {
            list-style: none;
            width: 100%;
            padding: 0 7px;
            margin-bottom: 15px;
            float: none;
            &:first-child {
                max-width: 305px;
            }
        }
        input[type="text"],
        input[type="email"],
        textarea,
        input[type="search"] {
            font-family: $font-family-sans-serif;
            display: block;
            width: 100%;
            background: #ffffff;
            color: #9B9B9B;
            font-size: rem-calc(14);
            line-height: 1.16667;
            border: 0px;
            padding: 7px 16px;
            border-radius: 3px;
        }
        input,
        textarea {
            &:-moz-placeholder {
                opacity: 1;
                color: #9B9B9B;
            }
            &::-moz-placeholder {
                opacity: 1;
                color: #9B9B9B;
            }
            &::-webkit-input-placeholder {
                opacity: 1;
                color: #9B9B9B;
            }
            &:-ms-input-placeholder {
                opacity: 1;
                color: #9B9B9B;
            }
        }
        textarea {
            min-height: 119px;
        }
        .button1 {
            width: 100%;
            padding: 8px 0;
            border: 0;
            max-width: 115px;
        }
    }
}
@media (max-width: 767px) {
    .enquiry-form {
      &.form {
        h3 {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 20px;
        }
    }
    }
}
@media (max-width: 543px) {
    .enquiry-form {
      &.form {
        li {
            width: 100%;
        }
    }
    }
}
