.quote{
  padding: 50px 0 25px 0;
  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li{
max-width: 790px;
display: inline-block;

    }
  }
  blockquote{
    &:before{
      content: "";
      background-image: url(../images/testimonial-quotes.png);
      width: 30px;
      height: 20px;
      display: inline-block;
      background-repeat: no-repeat;}
      p{
        font-size: rem-calc(24);
        font-weight: bold;
        line-height: 1.33;
        text-align: center;
        color: #4a4a4a;
        margin-bottom:20px;
            padding-top: 35px;
      }

    cite{
        font-size: rem-calc(14);
        font-style: normal;
        color: #4a4a4a;
        font-weight: bold;
        margin-bottom: 45px;
        display: block;
        text-align: center;
    }
  }
.controls {
	text-align: center;
	a {
		border-radius: 50%;
		display: inline-block;
		background: #d8d8d8;
		height: 13px;
		width: 13px;
		margin: 0px 5px;
		overflow: hidden;
		text-indent: -2000px;
		border: 1px solid #ffffff;
		&:hover {
			background: #60afbb;
		}
	}
	a.selected {
		background: #60afbb;
	}
}

}
