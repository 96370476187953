body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

a {
    color: $orange;
    text-decoration: underline;
    @include cubic-transition();

    &:hover, &:focus {
      color: $orange;
    }

    &:focus {
        outline: none;
    }
}

b , strong {
  font-weight: 700;
}

h1, h2 {
  line-height: (48 / 36);
}

h3 {
  line-height: (27 / 22);
}

h4 {
  line-height: (22 / 18);
}

p {
  line-height: (27 / 16);
  margin-bottom: 30px;
  &.lead {
    font-size: rem-calc(24);
    line-height: (32 / 24);
  }
}

@media screen and (max-width:480px) {
  h1, h2 {
    font-size: rem-calc(26);
  }
  p {
    &.lead {
      font-size: rem-calc(18);
      line-height: (28 / 18);
    }
  }
}
