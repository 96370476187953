.logo {
  a {
    display: inline-block;
    background: url('../images/logo.svg') no-repeat;
  }
}

#header {
  .logo {
    float: left;
    a {
      width: 144px;
      height: 55px;
    }
  }
}

#footer {
  .logo {
    text-align: right;
    margin-bottom: 35px;
    a {
      background-image: url('../images/logo2.svg');
      width: 102px;
      height: 33px;
      vertical-align: top;
    }
  }
}

@media screen and (max-width:767px) {
  #footer {
    .logo {
      text-align: left;
      margin-bottom: 25px;
    }
  }
}
