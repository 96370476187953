#main-navigation {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  li {
    display: inline-block;
    color: $white;
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(14);
    line-height: (17 / 14);
    font-weight: 700;
    margin-right: 35px;
    &:last-child {
      margin-right: 0px;
    }
    &.parent {
      li {
        a {
          &:before {
            display: block;
            content: "\f107";
            font-family: 'FontAwesome';
            font-size: rem-calc(20);
            @include vertical-center;
            right: 20px;
            font-weight: 700;
          }
        }
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .sub-menu {
    background: $white;
    padding-top: 13px;
    border-bottom: 1px solid $blue-light;
    position: absolute;
    left: 0;
    top: 77px;
    width: 100%;
    z-index: 9;
    display: none;
    li {
      display: inline-block;
      color: $black;
      font-family: $montserrat-sans-serif;
      font-size: rem-calc(14);
      line-height: (18 / 14);
      font-weight: 400;
      &:first-child {
        margin-left: -16px;
        a {
          padding-left: 16px;
        }
      }


      a {
        display: block;
        padding: 11px 54px 9px 24px;
        color: inherit;
        position: relative;
        &:hover {
          text-decoration: none;
        }
      }

    }

  }
}

#header {
  &.active-sub-menu {
    #main-navigation {
      .sub-menu {
        display: block;
      }
    }
  }
}

#main-navigation {
  .sub-menu {
    li {
      &.active {
        color: #fff;
      }
    }
  }
}

.sport-theme {
  #main-navigation {
    .sub-menu {
      li.active {
        background-color: $sport-theme;
      }
    }
  }
}
.ski-theme {
  #main-navigation {
    .sub-menu {
      li.active {
        background-color: $ski-theme;
      }
    }
  }
}
.adventure-theme {
  #main-navigation {
    .sub-menu {
      li.active {
        background-color: #9EE9C4;
      }
    }
  }
}
.performing-arts-theme {
  #main-navigation {
    .sub-menu {
      li.active {
        background-color: $performing-arts-theme;
      }
    }
  }
}
.teacher-guide-theme {
  #main-navigation {
    .sub-menu {
      li.active {
        background-color: $teacher-guide-theme;
      }
    }
  }
}

@media screen and (max-width:1199px) {
  #main-navigation {
    li {
      font-size: rem-calc(13);
      margin-right: 10px;
    }
    .sub-menu {
      li {
        font-size: rem-calc(13);
      }
    }
  }
}

@media screen and (min-width:992px) {
  #main-navigation {
    display: block !important;
  }
}
@media screen and (max-width:991px) {
  #main-navigation {
    background: $white;
    position: absolute;
    left: 0;
    top: 77px;
    width: 100%;
    border-bottom: 1px solid $blue-light;
    z-index: 99;
    display: none;
    li {
      display: block;
      font-size: rem-calc(14);
      color: $light-black;
      margin-right: 0px;
      margin-bottom: 10px;
      &.parent {
        li {
          a {
            &:before {
              right: 5px;
            }
          }
        }
      }
    }
    .sub-menu {
      position: relative;
      top: 0;
      border-bottom: 0px;
      padding-top: 5px;
      .container {
        max-width: 100%;
      }
      li {
        display: block;
        font-size: rem-calc(14);
        margin: 0px;
        &:first-child {
          margin-left: 0px;
          a {
            padding-left: 5px;
          }
        }
        a {
          padding-right: 20px;
          padding-left: 5px;
          border-bottom: 1px solid $light-black;
        }
      }
    }
  }
}
