.trips-tours-landing {
   #content {
     padding: 0px;
   }
   .box1 {
     &.text-xs-right {
       .heading {
         &:after {
           left: auto;
           right: 0;
         }
       }
       .inner-wrap {
         float: right;
       }
     }
     .details {
       .inner-wrap {
         max-width: 870px;
         .inner {
           padding-right: 65px;
           padding-left: 90px;
         }
       }
       .button1 {
         font-weight: 400;
         padding: 12px 25px;
       }
     }
   }
   .heading {
     &:after {
       position: absolute;
       content: '';
       display: inline-block;
       left: 0;
       top: auto;
     }
   }
 }

 @media screen and (min-width:1200px) {
   .trips-tours-landing {
     .box1 {
        max-height: 650px;
     }
   }
 }

 @media screen and (max-width:991px) {
   .trips-tours-landing {
     .box1 {
       .details {
         h2.heading {
             margin-bottom: 20px;
         }
         p {
           margin-bottom: 15px;
           &.lead {
             font-size: rem-calc(20);

           }
         }
       }
     }
   }
 }

 @media screen and (max-width:767px) {
   .trips-tours-landing {
     .box1 {
       .image {
         display: none;
       }
       .details {
         position: relative;
         .inner-wrap {
           .inner {
             padding: 30px 20px 40px;
           }
         }
         .button1 {
           padding: 12px;
         }
       }
       &.text-xs-right {
         .inner-wrap {
           float: none;
         }
       }
     }
   }
}
