.box2 {
  background: #EDF1F2;
  padding: 45px 40px;
  h3 {
    margin-bottom: 20px;
    &.display-6 {
      font-size: rem-calc(16);
      line-height: (22 / 16);
      margin-bottom: 15px;
    }
    &.display-7 {
       color: #3b3d40;
       letter-spacing: -0.2px;
       font-size: rem-calc(15);
    }
  }
  address {
    margin: 0;
    p {
      margin-bottom: 15px;
    }
  }
}

@media (max-width:767px){
  .box2 {
    padding: 20px;
  }
}
