#header {
  #main-navigation, .search-form {
    float: right;
  }
  #main-navigation {
    padding-top: 20px;
  }
}

@media screen and (min-width:992px) {
  #header {
    &.active-sub-menu {
      margin-bottom: 51px;
    }
  }
}

@media screen and (max-width:991px) {
  #header {
    #main-navigation {
      padding: 10px 15px;
    }
  }
}
