#top-bar {
  background: $orange;
  padding: 4px 0px;
}

#header {
  background: $blue-dark;
  padding: 12px 0px 0px;
  position: relative;
}

#content {
  padding: 30px 0;
}

#footer {
  background: $white;
  padding: 60px 0px 45px;
}

@media (max-width: 767px) {
  #footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
