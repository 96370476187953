.button1 , .button2 , .button3 {
    display: inline-block;
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(14);
    line-height: (18 / 14);
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    &:hover , &:focus {
      text-decoration: none;
    }
}

.button1 {
    background-color: $orange;
    color: $white;
    padding: 10px;
    text-align: center;
    border-radius: 3px;
    text-decoration: none;
    &:hover , &:focus {
      color: $white;
  }
}

.button2 {
  background: $white;
  color: $orange;
  padding: 8px 25px;
  text-decoration: none;
}

.button3 {
  border-radius: 3px;
  background: #fff;
  padding: 5px;
  text-align: center;
  border: solid 1px #ea6d32;
  color: #ea6d32;
  text-decoration: none;
  font-family: $font-family-sans-serif;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.button4 {
  display: inline-block;
  border: 1px solid #EDEDED;
  border-radius: 17px;
  color: #666666;
  font-size: rem-calc(10);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: (13 / 10);
  text-decoration: none;
  min-width: 140px;
  padding: 10px;
  font-family: $montserrat-sans-serif;
  &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.button5 {
  display: inline-block;
  background: $white;
  border: 1px solid #EDEDED;
  border-radius: 17px;
  color: #3C3D41;
  font-size: rem-calc(10);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: (13 / 10);
  text-decoration: none;
  min-width: 140px;
  padding: 10px;
  font-family: $montserrat-sans-serif;
  &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.button6 {
    background-color: $orange;
    color: $white;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    font-family: $roboto-sans-serif;
    font-size: rem-calc(16);
    line-height: (16 / 16);
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    &:hover , &:focus {
      text-decoration: none;
    }
}

.link {
  background: url('../images/arrow1.svg') no-repeat right 9px;
  display: inline-block;
  font-size: rem-calc(14);
  line-height: (26 / 14);
  font-weight: 700;
  color: $orange;
  text-decoration: underline;
  padding-right: 20px;
  &:hover, &:focus {
    color: $orange;
  }
}

.read-more-button {
  display: inline-block;
  font-weight: 700;
  font-size: rem-calc(10);
  letter-spacing: 2px;
  border-radius: 3px;
  text-transform: uppercase;
  background: rgba(0,0,0,0);
  padding: 10px;
  border: 1px solid #fff;
  text-decoration: none;
  color: $white;
  font-family: $montserrat-sans-serif;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.read-more-button2 {
  display: inline-block;
  font-weight: 700;
  font-size: rem-calc(10);
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 4px;
  background: rgba(0,0,0,0);
  padding: 10px;
  border: 1px solid #E1E1E1;
  color: $black;
  text-decoration: none;
  font-family: $montserrat-sans-serif;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.load-more-button {
  display: block;
  font-weight: 700;
  font-size: rem-calc(15);
  letter-spacing: 2px;
  background: #B8C6C7;
  border-radius: 4px;
  padding: 10px;
  color: $white;
  text-decoration: none;
  text-align: center;
  font-family: $montserrat-sans-serif;
  &:hover , &:focus {
    color: $white;
    text-decoration: none;
  }
}

.box1 {
  &.sport-theme {
    .button1 {
      background-color: $sport-theme;
    }
  }
  &.ski-theme {
    .button1 {
      background-color: $green-dark;
    }
  }
  &.adventure-theme {
    .button1 {
      background-color: $adventure-theme;
      color: $blue-dark;
    }
  }
  &.performing-arts-theme {
    .button1 {
      background-color: $performing-arts-theme;
    }
  }
  &.teacher-guide-theme {
    .button1 {
      background-color: $teacher-guide-theme;
    }
  }
}

.like-button {
  display: inline-block;
  position: relative;
  padding: 10px 40px;
  span {
    background: #b8c6c7;
    border-radius: 17px;
    color: #ffffff;
    display: block;
    height: 15px;
    margin-top: -8px;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 21px;
  }
  &:before {
    color: #b8c6c7;
    content: "\f004";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 15px;
    left: 17px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: inherit;
  }
}

.facebook-button {
  display: inline-block;
  position: relative;
  padding: 10px 40px;
  background: #225B99;
  border: 1px solid #225B99;
  color: $white;
  &:before {
    color: $white;
    content: "\f09a";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 15px;
    left: 17px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: $white;
  }
}

.twitter-button {
  display: inline-block;
  position: relative;
  padding: 10px 40px;
  color: $white;
  background: #00ADF2;
  border: 1px solid #00ADF2;
  &:before {
    color: $white;
    content: "\f099";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 15px;
    left: 17px;
    margin-top: -9px;
    position: absolute;
    top: 50%;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: $white;
  }
}

.sample {
    background: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #ea6d32;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
}
