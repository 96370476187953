.tour-offers {
  background: #152023;
  margin-bottom: 27px;
  position: relative;
  .previous-button, .next-button {
    font-size: rem-calc(48);
    color: rgba(255, 255, 255, 0.43);
    text-decoration: none;
    @include vertical-center;
    z-index: 9;
  }
  .previous-button {
    left: 45px;
  }
  .next-button {
    right: 45px;
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }
  li {
    width: 380px;
    float: left;
    position: relative;
    text-align: center;
    color: $white;
    a {
      color: inherit;
    }
    img {
      width: 100%;
    }
  }
  .details {
    @include absolute-center;
    width: 100%;
    max-width: 270px;
    z-index: 9;
    padding: 10px;
    h3 {
      font-size: rem-calc(20);
      line-height: (26 / 20);
      margin-bottom: 36px;
      letter-spacing: 1px;
    }
    p {
      font-family: $montserrat-sans-serif;
      line-height: (19 / 16);
      margin-bottom: 40px;
      letter-spacing: 0.5px;
    }
    .button1 {
      min-width: 144px;
      font-weight: 400;
    }
  }
}
@media screen and (max-width:599px) {
  .tour-offers {
    .previous-button {
      left: 15px;
    }
    .next-button {
      right: 15px;
    }
    .details {
      h3 {
        font-size: rem-calc(18);
        margin-bottom: 25px;
      }
      p {
        margin-bottom: 25px;
      }
    }
  }
}

