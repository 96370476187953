.social-feed {
  background: $blue-dark;
  color: $white;
  position: relative;

  &:first-child {
    border-right: 1px solid $brand-primary;
  }

  .social-icon {
    width: 56px;
    height: 56px;
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
    text-align: center;
    font-size: rem-calc(24);
    line-height: (56 / 24);
    &.fb {
      background: #3C599B;
    }
    &.twitter {
      background: #299AC4;
    }
  }

  .time {
    display: block;
    color: $white;
    font-size: rem-calc(12);
    line-height: (17 / 12);
  }

  h4, li {
    color: $white;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  h4 {
    margin-bottom: 15px;
  }

  p {
    font-size: rem-calc(14);
    line-height: (19 / 14);
    margin-bottom: 20px;
    a {
      color: $green-dark;
      text-decoration: none;
    }
  }

  .user-profile-link {
    display: inline-block;
    padding-left: 45px;
    color: $white;
    font-size: rem-calc(12);
    line-height: (17 / 12);
    text-decoration: none;
    .avatar {
      background: url('../images/logo3.svg') no-repeat;
      display: block;
      width: 42px;
      height: 42px;
      float: left;
      margin-bottom: 5px;
      margin-left: -42px;
    }
    .fullname {
      display: block;
    }
    p {
      margin-bottom: 0px;
    }
  }

  .actions {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 15px;
      font-size: rem-calc(12);
      line-height: (17 / 12);
      color: $blue-light;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width:767px) {
  .social-feed {
    &:first-child {
      border-right: 0px;
      border-bottom: 1px solid $brand-primary;
    }
  }
}
