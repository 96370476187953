.entry {
  padding: 50px 45px 10px 0px;
  .heading {
    max-width: 400px;
  }
  p {
    margin-bottom: 15px;
    &.link2 {
       font-weight: 700;
       font-size: rem-calc(14);
    }
  }
  .post {
     ul {
  margin: 0px 0px 30px;
  padding: 0px;
  list-style: none;
  li {
    font-size: rem-calc(18);
    line-height: (28 / 18);
    color: $brand-primary;
    padding-left: 30px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 12px;
      background: $brand-primary;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &.strengths {
    li {
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
}
  }
}

.entry {
    .post {
      .form {
        ul {
          margin: 0 -7px;
          padding: 0;
        }
        li {
          list-style: none;
          width: 50%;
          padding: 0 7px;
          margin-bottom: 15px;
          &.full-width {
            width: 100%;
          }
          &:before {
            display: none;
          }
         }
        }
    }
}

@media screen and (max-width:991px) {
  .entry {
    padding: 30px 0px 10px 0px;
  }
}

@media (max-width:543px){
  .entry {
    .post {
      .form {
        li {
          width: 100%;
        }
      }
    }
  }
}
