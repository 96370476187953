#top-bar {
  .column {
    display: inline-block;
    vertical-align: middle;
    margin: 0px -2px;
  }

  p {
    white-space: nowrap;
    font-family: $montserrat-sans-serif;
    font-size: rem-calc(12);
    line-height: 32px;
    color: $white;
    margin: 0px;
    letter-spacing: 1px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

@media screen and (max-width:1199px) {
  #top-bar {
    .btn-wrap {
      text-align: center;
    }
    .column {
      &:last-child {
        display: block;
      }
    }
  }
}

@media (max-width:991px){
  #top-bar {
    p {
      white-space: normal;
      line-height: 1;
    }
  }
}

@media screen and (max-width:767px) {
  #top-bar {
    p {
      margin-bottom: 10px;
    }
    .column {
      display: block;
      margin: 0px 0px 5px;
      text-align: center;
    }
  }
}
