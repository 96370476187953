/*
*featured-section
*/
.featured-section .testimonial p.lead { font-weight: 400; }

/*
*testimonial2
*/
.testimonial2 {
  padding: 30px 0 15px;
  margin-bottom: 30px;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
    p {
      font-size: rem-calc(24);
      color: #3B3D40;
      letter-spacing: -0.4;
      font-style: italic;
      line-height: (33 / 24);
      margin-bottom: 20px;
    }
}

#container {
  min-width: 320px;
  overflow-x: hidden;
}

.underline {
  text-decoration: underline;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

@media (max-width:767px){
  .sportsfest-column {
    margin-bottom: 20px;
  }
}

img.alignleft { float: left; margin: 0 10px 10px 0; display: inline; }
img.alignright { float: right; margin: 0 0px 10px 10px; display: inline; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; margin-bottom: 10px; }

.clear {
    clear: both;
    height: 1px;
    line-height: 1px;
}

.secondary-black {
  color: #3B3D40;
}

.semi-bold {
  font-weight: 600;
}

.normal {
  font-weight: 400;
}

.box1 {
  &.sportsfest-template3 {
     .details {
       background: rgba(0,0,0,0);
       .inner-wrap {
         .inner {
           position: absolute;
           bottom: 0;
           left: 0;
           display: block;
         }
       }
     }
  }
}
