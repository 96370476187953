.tag-share-section {
  max-width: 465px;
  margin: 0 auto;
  margin-bottom: 90px;
  .tags-section {
    margin-bottom: 40px;
    .tag {
      border: 1px solid #EDEDED;
      border-radius: 4px;
      font-size: rem-calc(9);
      font-weight: 700;
      text-align: center;
      color: #838D8F;
      text-decoration: none;
      text-transform: uppercase;
      padding: 4px 14px;
      margin-right: 10px;
      letter-spacing: 2px;
      font-family: $montserrat-sans-serif;
    }
  }
  .share-section {
      .button4 {
        margin-right: 12px;
      }
  }
}

@media (max-width:543px){
  .tag-share-section {
    max-width: 100%;
    margin: 0 auto 30px;
    .share-section {
      .button4 {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
