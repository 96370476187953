.section1 {
  background: #dde9eb;
  padding: 40px 0;
    h3 {
      margin-bottom: 30px;
    }
}


@media (min-width:1200px){
  .popular-section {
    .container {
      max-width: 980px;
    }
  }
}
