.modal {
  top: 25px;
  bottom: 25px;
}
.modal-dialog {
  margin: 0 auto;
  max-width: 745px;
}
.modal-content {
  background: #dde9eb;
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 2, 4, 0.5);
  padding: 20px 45px 65px 20px;
}

h4 {
  &.modal-title {
    font-size: rem-calc(24);
    line-height: (29 / 24);
    margin-bottom: 25px;
  }
}

.modal-footer , .modal-body , .modal-header {
  padding: 0;
}

.close {
    font-family: fontawesome;
    color: #388299;
    font-size: rem-calc(18);
    font-weight: 400;
    opacity: 1;
    position: absolute;
    right: 25px;
    top: 20px;
}

.modal-body {
  background: #fff;
  padding: 35px 20px 10px;
  height: 680px;
  overflow: scroll;
  h6 {
    font-family: $font-family-sans-serif;
    &.modal-heading {
      border-bottom: 1px solid #979797;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width:767px){
  .modal-dialog {
    max-width: 620px;
  }
  h4 {
  &.modal-title {
    font-size: rem-calc(20);
  }
}
}

@media (max-width:640px){
  .modal-dialog {
    max-width: 520px;
  }
}

@media (max-width:543px){
  .modal-dialog {
    max-width: 440px;
  }
}

@media (max-width:480px){
  .modal-dialog {
    max-width: 360px;
  }
}

@media (max-width:380px){
  .modal-dialog {
    max-width: 300px;
  }
}
