.list4 {
  margin: 0 -5% 30px;
  padding: 0;
  li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 -3px 15px;
    width: 25%;
    text-align: center;
    padding: 0 5%;
    &:nth-child(4n){
      padding-right: 0;
    }
    &:nth-child(4n+1){
      padding-left: 0;
    }
  }
}

@media (max-width:991px){
  .list4 {
    margin-bottom: 70px;
    li {
      width: 50%;
      &:nth-child(4n){
        padding-right: 5%;
      }
      &:nth-child(4n+1){
        padding-left: 5%;
      }
    }
  }
}

@media (max-width:767px){
  .list4 {
    margin-bottom: 45px;
  }
}

@media (max-width:543px){
  .list4 {
    margin: 0;
    li {
      width: 100%;
    }
  }
}
