.menu-btn {
    display: none;
    float: right;
    height: 18px;
    width: 25px;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    margin-top: 23px;
    border: 0px;
    position: relative;
    z-index: 999;
    @include cubic-transition();
    span.icon-bar {
      background: $secondary-color;
      display: block;
      cursor: pointer;
      height: 3px;
      width: 25px;
      margin-top: 4px;
      position: relative;
      transform: rotate(0deg);
        &:first-child {
          margin-top: 0;
        }
        &:first-child, &:last-child {
            @include cubic-transition();
        }
    }
    &.active {
        span.icon-bar {
            background: $white;
            &:first-child + span { background-color: transparent; opacity: 0; }
            &:first-child { transform: rotate(45deg); left: 0px; top: 7px; }
            &:last-child { transform: rotate(-45deg); left: 0px; top: -7px; }
        }
    }
}

@media screen and (max-width: 991px) {
  .menu-btn {
    display: block;
  }
}
