$secondary-color:       #F26522;
$orange:                #EA6D32;
$orange-dark:           #B57E64;
$purple:                #A186BE;
$blue-dark:             #024256;
$blue-light:            #BDD7DB;
$blue-lighter:          #DDE9EB;
$green:                 #9EE9C4;
$green-dark:            #60AFBB;
$green-light:           #63DDA1;

$sport-theme:             $orange;
$ski-theme:               $brand-primary;
$adventure-theme:         $green-light;
$performing-arts-theme:   $purple;
$teacher-guide-theme:     $brand-primary;

$ski-tag:                 $green-dark;

// 4BCA8B
